<template>
  <el-dialog
    :title="dialogTitle"
    :visible="true"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <section>
      <el-form
        ref="form"
        :model="formData"
        label-position="top"
        :rules="formRules"
      >
        <el-form-item label="名稱" prop="name">
          <el-input v-model="formData.name" placeholder="請輸入類別名稱" />
        </el-form-item>

        <el-form-item label="收納類別" prop="order">
          <ProductCategorySelect :model.sync="formData.categorys" multiple />
        </el-form-item>
      </el-form>
    </section>

    <div slot="footer">
      <el-button
        plain
        @click="cancel"
      >
        返回
      </el-button>
      <el-button type="primary" @click="dialogConfirm">
        {{
          dialogType === 'create' ? '新增' : '儲存'
        }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import ProductCategorySelect from '@/components/Search/ecommerce/ProductCategorySelect.vue'
import { noEmptyRules } from '@/validation/index'
import {
  CreateCategoryGroup,
  UpdateCategoryGroup,
} from '@/api/ecommerce/categoryGroup'
import formUtils from '@/utils/form'
import { mapGetters } from 'vuex'
import { extractList } from '@/utils/helper'

export default {
  name: 'CategoryGroupCreateDialog',
  components: { ProductCategorySelect },
  // mixins: [imageMixin],
  props: ['dialogTitle', 'dialogType', 'selectRow'],
  computed: {
    ...mapGetters(['shop']),
    categorysList () {
      if (this.formData.categorys.length === 0) return []
      return extractList('id', this.formData.categorys)
    },
  },
  data: () => ({
    uploadDialog: false,
    avatarChanged: false,
    formData: {
      name: '',
      categorys: [],
    },

    formRules: {
      name: [noEmptyRules('請輸入類別名稱')],
    },
  }),

  mounted () {
    if (!this.selectRow) return
    this.syncFormData()
  },
  methods: {
    confirm () {
      this.$emit('confirm')
      this.$emit('close')
    },

    cancel () {
      // this.resetForm()
      this.$emit('cancel')
      this.$emit('close')
    },

    async checkForm () {
      return await formUtils.checkForm(this.$refs.form)
    },

    async createCategoryGroup () {
      const [res, err] = await CreateCategoryGroup({
        shopId: this.shop,
        name: this.formData.name,
        ecommerceCategories: this.categorysList,
      })
      if (err) return this.$message.error(err)
      this.$emit('refresh')
      this.$message.success('新增成功')
      this.$emit('close')
    },

    async updateCategoryGroup () {
      const [res, err] = await UpdateCategoryGroup({
        shopId: this.shop,
        id: this.selectRow.id,
        name: this.formData.name,
        ecommerceCategories: this.categorysList,
      })
      if (err) return this.$message.error(err)
      this.$emit('refresh')
      this.$emit('close')
    },

    async dialogConfirm () {
      if (!(await this.checkForm())) return
      const type = this.dialogType
      if (type === 'create') this.createCategoryGroup()
      if (type === 'update') this.updateCategoryGroup()
    },

    syncFormData () {
      if (!this.selectRow) return
      this.formData.name = this.selectRow.name
      this.formData.categorys = this.selectRow.EcommerceCategories
    },
  },

}
</script>

<style lang="postcss">
 .v-modal {
  z-index: 200 !important;
}
</style>
