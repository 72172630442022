import { admin2 } from '@/api/instance'

export const UpdateSidebarConfig = async ({
  shopId,
  enable,
  ecommerceActivityOrder,
  ecommerceCategoryGroupOrder,
}) => {
  const res = await admin2({
    method: 'put',
    url: `/${shopId}/ecommerceSidebarConfig`,
    data: {
      enable,
      ecommerceActivityOrder,
      ecommerceCategoryGroupOrder,
    },
  })
  return res
}

export const FindSidebarConfig = async ({ shopId }) => {
  const res = await admin2({
    method: 'get',
    url: `/${shopId}/ecommerceSidebarConfig`,
  })
  return res
}
