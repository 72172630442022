<template>
  <main class="sidebar-setting">
    <SectionTitle title="側邊欄功能設定" hint="開啟側邊欄功能，能讓類別群組於側邊欄顯示。類別群組能夠收納類別，使類別更明確易找。" hideBtn />

    <el-switch v-model="enable" style="margin-top: 28px; margin-bottom:24px;" active-text="開啟" inactive-text="關閉" @change="updateSidebarConfig" />

    <div>
      <SectionTitle title="活動" hideBtn style="margin-bottom: 20px" />
      <ActivitysDragTable v-if="!loading" :activitysOrderList="activitysOrderList" @change="updateOrder" @sync="syncOrder" />
    </div>

    <div style="margin-top: 32px">
      <SectionTitle title="類別群組" btn="新增" style="margin-bottom: 20px" @edit="openCreateDialog = true" />

      <CategoryGroupDragTable
        v-if="!loading"
        :groupsOrderList="groupsOrderList"
        :openCreateDialog="openCreateDialog"
        @close="openCreateDialog = false"
        @change="updateOrder"
        @sync="syncOrder"
      />
    </div>
  </main>
</template>

<script>
import SectionTitle from '@/components/Title/SectionTitle.vue'
import CategoryGroupDragTable from './components/CategoryGroupDragTable'
import ActivitysDragTable from './components/ActivitysDragTable.vue'
import { UpdateSidebarConfig, FindSidebarConfig } from '@/api/ecommerce/sidebar'
import { mapGetters } from 'vuex'
import { map, clone } from 'lodash'

export default {
  name: 'SidebarSetting',
  components: { SectionTitle, ActivitysDragTable, CategoryGroupDragTable },
  computed: {
    ...mapGetters(['shop']),
    activitysList () {
      return map(this.activitys, data => ({
        type: 'ecommerceActivity',
        ecommerceActivityId: data.id,
      }))
    },
    groupsList () {
      return map(this.groups, data => ({
        type: 'ecommerceCategoryGroup',
        ecommerceCategoryGroupId: data.id,
      }))
    },
  },
  data: () => ({
    loading: false,
    openCreateDialog: false,
    updateType: '',
    enable: false,
    activitys: [],
    groups: [],
    activitysOrderList: [],
    groupsOrderList: [],
  }),

  async mounted () {
    this.loading = true
    await this.findSidebarConfig()
    this.loading = false
  },
  methods: {
    async updateSidebarConfig () {
      const [res, err] = await UpdateSidebarConfig({
        shopId: this.shop,
        enable: this.enable,
        ecommerceActivityOrder: this.activitysList,
        ecommerceCategoryGroupOrder: this.groupsList,
      })

      if (err) return this.$message.error(err.msg)
      this.$message.success('更新成功！')
      await this.findSidebarConfig()
    },

    async findSidebarConfig () {
      const [res, err] = await FindSidebarConfig({ shopId: this.shop })
      console.log('err', err)
      if (err) return
      this.activitysOrderList = res.ecommerceActivityOrder
      this.groupsOrderList = res.ecommerceCategoryGroupOrder
      this.enable = res.enable
    },

    async updateOrder (newUpdate) {
      this.updateType = newUpdate.type
      if (newUpdate.type === 'activity') this.activitys = clone(newUpdate.data)
      if (newUpdate.type === 'group') this.groups = clone(newUpdate.data)
      await this.updateSidebarConfig()
    },

    syncOrder (syncType) {
      if (syncType.type === 'activity') this.activitys = clone(syncType.data)
      if (syncType.type === 'group') this.groups = clone(syncType.data)
    },
  },
}
</script>

<style lang="scss" scoped>
.sub-title {
  @apply mb-[10px] text-[24px] font-medium;

  .title-hint {
    @apply text-primary-100 mr-[5px];
  }
}

.title {
  @apply font-medium text-[24px] leading-[34.75px];
}

.hint {
  @apply text-sub text-gray-80;
}
</style>
