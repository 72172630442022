import { admin2 } from '@/api/instance'

export const CreateCategoryGroup = async ({
  shopId,
  name,
  ecommerceCategories,
}) => {
  const res = await admin2({
    method: 'post',
    url: `/${shopId}/ecommerceCategoryGroup`,
    data: {
      name,
      ecommerceCategories,
    },
  })
  return res
}

export const GetCategoryGroup = async ({
  shopId,
  start,
  limit,
}) => {
  const res = await admin2({
    method: 'get',
    url: `/${shopId}/ecommerceCategoryGroup`,
    params: {
      start,
      limit,
    },
  })
  return res
}

export const FindCategoryGroup = async ({
  shopId,
  id,
}) => {
  const res = await admin2({
    method: 'get',
    url: `/${shopId}/ecommerceCategoryGroup/${id}`,
  })
  return res
}

export const GetCategoryGroupCount = async ({
  shopId,
}) => {
  const res = await admin2({
    method: 'get',
    url: `/${shopId}/ecommerceCategoryGroup/count`,
  })
  return res
}

export const UpdateCategoryGroup = async ({
  shopId,
  id,
  name,
  ecommerceCategories,
}) => {
  const res = await admin2({
    method: 'put',
    url: `/${shopId}/ecommerceCategoryGroup/${id}`,
    data: {
      name,
      ecommerceCategories,
    },
  })
  return res
}

export const DeleteCategoryGroup = async ({
  shopId,
  id,
}) => {
  const res = await admin2({
    method: 'delete',
    url: `/${shopId}/ecommerceCategoryGroup/${id}`,
  })
  return res
}
