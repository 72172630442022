<template>
  <div class="fake-table">
    <div class="fake-table-header">
      <p />
      <p>排序</p>
      <p>名稱</p>
    </div>
    <div v-loading="loading" class="fake-table-body">
      <EmptyBlock v-if="!activitys.length" />
      <draggable
        v-model="activitys"
        ghost-class="ghost"
        @change="$emit('change', { type: 'activity', data: activitys })"
      >
        <div
          v-for="(act, index) in activitys"
          :key="act.id"
          class="act-draggable"
        >
          <p><img src="@/assets/icon/drag.svg" class="m-auto"></p>
          <p>{{ index }}</p>
          <p>{{ act.name }}</p>
        </div>
      </draggable>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { GetActivity } from '@/api/ecommerce/activity'
import { mapGetters } from 'vuex'
import { map, find, remove, filter } from 'lodash'

export default {
  name: 'ActivitysDragTable',
  components: { draggable },
  props: ['activitysOrderList'],
  data: () => ({
    loading: false,
    activitys: [],
    tableOptions: {
      page: 0,
      pageLimit: 10,
    },
  }),
  computed: {
    ...mapGetters(['shop']),
  },

  watch: {
    async activitysOrderList () {
      this.loading = true
      await this.syncOrderListData()
      this.loading = false
    },
  },

  async mounted () {
    this.loading = true
    await this.syncOrderListData()
    this.$emit('sync', { type: 'activity', data: this.activitys })
    this.loading = false
  },

  methods: {
    async getActivity () {
      const [res, err] = await GetActivity({
        shopId: this.shop,
        start: this.tableOptions.page,
        limit: this.tableOptions.pageLimit,
        isStorewideActivity: false,
      })

      if (err) return this.$message.error(err)
      return res
    },

    async syncOrderListData () {
      let acts = await this.getActivity()
      acts = acts.filter(act => act.enable && !act.isRemove)
      this.activitys = map(this.activitysOrderList, (data) => {
        const exist = find(acts, (i) => i.id === data.ecommerceActivityId)
        if (exist) {
          remove(acts, (i) => i.id === data.ecommerceActivityId)
        }

        if (!exist) {
          return {
            id: '',
            name: '',
          }
        }

        return {
          id: data.ecommerceActivityId,
          name: exist.name,
        }
      })
      this.activitys = filter(this.activitys, i => i.id)
      this.activitys.push(...acts)
    },
  },
}
</script>

<style lang="postcss" scoped>
.fake-table {
  @apply rounded-[4px];
  box-shadow: 0px 1px 13px rgb(0 0 0 / 10%);
}
.fake-table-header {
  @apply bg-primary-100 text-white py-[17px];
  @apply grid text-center rounded-tl-[4px] rounded-tr-[4px];
  grid-template-columns: 100px 100px 1fr;
}

.fake-table-body {
  @apply bg-white;
}

.act-draggable {
  @apply grid items-center py-[20px] cursor-move;
  grid-template-columns: 100px 100px 1fr;
  box-shadow: inset 0px -1px 0px #DEDEDE;
}

.act-draggable p {
  @apply  text-center;
}

.act-draggable:hover {
  background: rgba(150, 150, 150, 0.2);
}

.act-draggable p:nth-child(1) {
  opacity: 0;
}

.act-draggable:hover p:nth-child(1) {
  opacity: 1;
}

.ghost {
  background-color: rgba(150, 150, 150, 0.2);
}
</style>
