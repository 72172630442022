<template>
  <div class="fake-table">
    <div class="fake-table-header">
      <p />
      <p>排序</p>
      <p>名稱</p>
      <p>類別</p>
      <p>操作</p>
    </div>
    <div v-loading="loading" class="fake-table-body">
      <EmptyBlock v-if="!categoryGroups.length" />
      <draggable
        v-model="categoryGroups"
        ghost-class="ghost"
        @change="updateOrder"
      >
        <div
          v-for="(group, index) in categoryGroups"
          :key="group.id"
          class="act-draggable"
        >
          <p><img src="@/assets/icon/drag.svg" class="m-auto"></p>
          <p>{{ index + 1 }}</p>
          <p>{{ group.name }}</p>
          <p>{{ listCategory(group.EcommerceCategories) }}</p>
          <div>
            <el-button class="table-opt-edit" type="text" @click="openDialog('update'),(selectRow = group)">編輯</el-button>
            <el-button class="table-opt-del" type="text" @click=";(deleteDialog = true), (selectRow = group)">刪除</el-button>
          </div>
        </div>
      </draggable>
    </div>

    <CategoryGroupCreateDialog
      v-if="showDialog"
      :dialogTitle="dialogTitle"
      :dialogType="dialogType"
      :selectRow="selectRow"
      @refresh="createGroup"
      @close="$emit('close'), showDialog=false, selectRow=null"
    />

    <DeleteDialog
      v-if="deleteDialog"
      title="提醒"
      content="刪除後將無法復原，確定要刪除？"
      width="40%"
      @close="deleteDialog = false"
      @delete="deleteServiceCategory(), (deleteDialog = false)"
    />
  </div>
</template>

<script>
import EmptyBlock from '@/components/EmptyBlock'
import draggable from 'vuedraggable'
import CategoryGroupCreateDialog from './CategoryGroupCreateDialog.vue'
import DeleteDialog from '@/components/Dialog/DeleteDialog'
import {
  GetCategoryGroup,
  DeleteCategoryGroup,
} from '@/api/ecommerce/categoryGroup'
import { mapGetters } from 'vuex'
import { dialogTitle } from '@/utils/dialog'
import { map, find, remove, filter } from 'lodash'

export default {
  name: 'ActivitysDragTable',
  components: { draggable, DeleteDialog, CategoryGroupCreateDialog, EmptyBlock },
  props: ['openCreateDialog', 'groupsOrderList'],
  computed: {
    ...mapGetters(['shop']),
    dialogTitle () {
      return dialogTitle(this.dialogType, {
        create: '新增商品類別',
        update: '編輯商品類別',
      })
    },
  },
  data: () => ({
    deleting: false,
    loading: false,
    selectRow: null,
    showDialog: false,
    deleteDialog: false,
    dialogType: '',
    categoryGroups: [],
  }),
  watch: {
    openCreateDialog (open) {
      if (open) this.openDialog('create')
    },
  },
  async mounted () {
    this.loading = true
    await this.syncOrderListData()
    this.$emit('sync', { type: 'group', data: this.categoryGroups })
    this.loading = false
  },
  methods: {
    updateOrder () {
      if (this.deleting) return
      this.$emit('change', { type: 'group', data: this.categoryGroups })
    },
    async syncOrderListData () {
      const groups = await this.getCategoryGroup()
      this.categoryGroups = map(this.groupsOrderList, (data) => {
        const exist = find(groups, (i) => i.id === data.ecommerceCategoryGroupId)
        if (exist) remove(groups, (i) => i.id === data.ecommerceCategoryGroupId)
        if (!exist) {
          return {
            id: '',
            name: '',
            EcommerceCategories: [],
          }
        }
        return {
          id: data.ecommerceCategoryGroupId,
          name: exist.name,
          EcommerceCategories: exist.EcommerceCategories,
        }
      })
      this.categoryGroups = filter(this.categoryGroups, i => i.id)
      this.categoryGroups.push(...groups)
    },

    async getCategoryGroup () {
      const [res, error] = await GetCategoryGroup({
        shopId: this.shop,
        name: this.nameSearch === '' ? undefined : this.nameSearch,
      })
      if (error) return this.$message.error(error)
      // this.categoryGroups = res
      return res
    },

    tagType (val) {
      let type = 'info'
      if (val) type = 'success'
      if (!val) type = 'danger'
      return type
    },

    async createGroup () {
      await this.syncOrderListData()
      this.$emit('change', { type: 'group', data: this.categoryGroups })
    },

    async getServiceCategory () {
      const startIndex = this.pageStartIndex
      const limit = this.tableOptions.pageLimit
      const [res, error] = await GetCategoryGroup({
        shopId: this.shop,
        start: startIndex,
        limit,
        name: this.nameSearch === '' ? undefined : this.nameSearch,
      })
      if (error) return this.$message.error(error)
    },

    //= > 刪除產品類別
    async deleteServiceCategory () {
      const [res, error] = await DeleteCategoryGroup({
        shopId: this.shop,
        id: this.selectRow.id,
      })
      if (error) return this.$message.error(error)
      this.$message.success('刪除成功')
      await this.syncOrderListData()
      // this.$emit('change', {type:'group', data:this.categoryGroups})
    },

    listCategory (groups) {
      const list = map(groups, 'name')
      return list.join('、')
    },

    async openDialog (type) {
      this.dialogType = type
      this.showDialog = true
    },
  },
}
</script>

<style lang="postcss" scoped>
.fake-table {
  @apply rounded-[4px];
  box-shadow: 0px 1px 13px rgb(0 0 0 / 10%);
}
.fake-table-header {
  @apply bg-primary-100 text-white py-[17px];
  @apply grid text-center rounded-tl-[4px] rounded-tr-[4px];
  grid-template-columns: 100px 100px 1fr 1fr 150px;
}

.fake-table-body {
  @apply bg-white;
}

.act-draggable {
  @apply grid items-center cursor-move py-[12px] text-center;
  box-shadow: inset 0px -1px 0px #DEDEDE;
  grid-template-columns: 100px 100px 1fr 1fr 150px;
}

.act-draggable:hover {
  background: rgba(150, 150, 150, 0.2);
}

.act-draggable p:nth-child(1) {
  opacity: 0;
}

.act-draggable:hover p:nth-child(1) {
  opacity: 1;
}

.ghost {
  background-color: rgba(150, 150, 150, 0.2);
}
</style>
